import React, { useState, ReactNode } from 'react';
import styled from 'styled-components/macro';

import PageContainer from '@as_core/pages/PageContainer';
import InputStructure from '@pages/addCompound/InputStructure';
import EditStructure from '@pages/addCompound/EditStructure';
import ReviewStructure from '@pages/addCompound/ReviewStructure';
import { Step, StepLabel, Stepper } from '@mui/material';

import { CompoundT } from '../type';
import { useNavigate } from 'react-router-dom';
import MenuButton from '@components/elements/MenuButton';
import BackArrowIcon from '@components/icons/backArrow.icon';

export interface Structure {
  mol?: string;
  smiles?: string;
}

export interface CompoundMetaT {
  filename: string;
  mol_svg: string;
  neighbors: Array<object>;
  svg: object;
  structure?: string;
}

const initCompoundMeta: CompoundMetaT = {
  filename: '',
  mol_svg: '',
  neighbors: [],
  svg: null,
  structure: '',
};

const STEPS = [
  { index: 0, name: 'SELECT', label: 'Enter Structure' },
  { index: 1, name: 'EDIT', label: 'Edit Structure' },
  { index: 2, name: 'ATTR', label: 'Review and Upload' },
];

const debug = false;
const PgCompoundsAdd = () => {
  const [structure, setStructure] = useState<Structure | null>();
  const [compound, setCompound] = useState<CompoundT | null>();
  const [filename, setFilename] = useState<string | null>();
  const [compoundMeta, setCompoundMeta] =
    useState<CompoundMetaT>(initCompoundMeta);

  const [activeStep, setActiveStep] = useState(STEPS[0]);
  const priorStep = () => setActiveStep(STEPS[activeStep.index - 1]);
  const nextStep = () => setActiveStep(STEPS[activeStep.index + 1]);

  const StepTS = (props: {children: ReactNode | ReactNode[], key: string}) => <Step {...props} />;

  const navigate = useNavigate();
  const handleBack = () => {
    if (debug) console.log('CompoundCompareData | Handle Back');
    navigate(-1);
  };

  return (
    <PageContainer title={'Add Compound'}>
      <AddCompoundContainer>
        <AddCompoundBanner>
          <MenuButton
            text={'Return to prior view'}
            onClick={handleBack}
            icon={<BackArrowIcon size={32} />}
            width={220}
            height={40}
          />
        </AddCompoundBanner>
        <NewCompoundContainer>
          <StyledStepper activeStep={activeStep.index} alternativeLabel>
            {STEPS.map(({ label }) => (
              <StepTS key={label}>
                <StepLabel>{label}</StepLabel>
              </StepTS>
            ))}
          </StyledStepper>
          <DataInputContainer>
            {activeStep.name === 'SELECT' && (
              <InputStructure
                setFilename={setFilename}
                setStructure={setStructure}
                nextStep={nextStep}
              />
            )}
            {activeStep.name === 'EDIT' && (
              <EditStructure
                structure={structure}
                filename={filename}
                setStructure={setStructure}
                setCompound={setCompound}
                setCompoundMeta={setCompoundMeta}
                priorStep={priorStep}
                nextStep={nextStep}
              />
            )}
            {activeStep.name === 'ATTR' && (
              <ReviewStructure
                compound={compound}
                compoundMeta={compoundMeta}
                priorStep={priorStep}
              />
            )}
          </DataInputContainer>
        </NewCompoundContainer>
      </AddCompoundContainer>
    </PageContainer>
  );
};

export default PgCompoundsAdd;

const AddCompoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 50px);
`;

const AddCompoundBanner = styled.div`
  display: flex;
  padding-left: 20px;
  height: 50px;
  width: 100%;
`;

const NewCompoundContainer = styled.div`
  width: calc(100vw - 100px);
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledStepper = styled(Stepper)`
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  margin: 15px 5px;
  background-color: transparent !important;
`;

const DataInputContainer = styled.div`
  display: flex;
`;
