import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {useNavigate} from "react-router-dom";
import { UserContext } from '@stores/UserContext';
import {OrderT} from "@business/types";
import useOrders from "@business/orders/useOrders";
import ViewOrderDetails from "@subApps/orders/components/Orders/ViewOrderDetails";
import DataLoading from "@as_core/elements/DataLoading";
import useRequests, {RequestT} from "@subApps/orders/hooks/useRequests";
import useVendors from "@subApps/orders/hooks/useVendors";

const debug = true;
const OrdersStripe = () => {
  const { user } = useContext(UserContext);
  const params = new URLSearchParams(window.location.search);
  const { getOrder, updateOrder } = useOrders();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [requestsCreated, setRequestsCreated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderT>(null);
  const canceled = params.has('canceled');
  const success = params.has('success');
  const order_id = params.get('order_id');
  const { createRequest } = useRequests();
  const { autoAssign, getRequestType } = useVendors();
  if (debug) console.log('OrdersStripe | START | success:', success, 'canceled:', canceled, 'order_id:', order_id);
  if (debug) console.log('OrdersStripe | START | loaded', loaded, 'isLoading', isLoading, 'requestsCreated', requestsCreated, 'isProcessing', isProcessing);

  const createRequests = async (newOrder: OrderT): Promise<RequestT[]> => {
    const promises = Object.keys(newOrder.order).map(async (cat_no: string, index): Promise<RequestT> => {
      // create the request - capture
      const order = newOrder.order[cat_no];
      if (debug) console.log('OrdersStripe | key:', cat_no, order);
      const newRequest = {
        name: newOrder.name + '_item_' + index,
        assigned_to: autoAssign(cat_no),
        user_id: user.authId,
        org_id: user.appInfo.repositories.current.uuid,
        order_id: newOrder.orderId,
        request_type: getRequestType(cat_no),
        cat_code: cat_no,
        prod_name: order.product.productName,
        quantity: order.quantity,
        unit_value: order.product.unitSize,
        status: 'Open'
      }
      // handle the 3-month companion service
      if (cat_no === 'AS-SCREEN-3RND') {
        newRequest['status'] = 'Delivered';
      }
      if (debug) console.log('OrdersStripe | newRequest', newRequest);
      return createRequest(newRequest);
    });
    const requests: RequestT[] = await Promise.all(promises);
    if (debug) console.log('requests ', requests);
    return requests;
  }

  // Need to:
  // - make sure only runs once on success (isLoading, loaded)
  // - if user refreshes and requests already created don't create again (requests.length !== 0)
  // - don't let requests be created multiple times due to renders (isProcessing)
  // Step 1: Load the order details that were created during Stripe Initiation and set states
  useEffect(() => {
    if (!loaded && !isLoading) {
      setIsLoading(true);
      getOrder(order_id).then((res) => {
        if (debug) console.log('OrdersStripe | USE_EFFECT | load order | res:', res);
        setIsLoading(false);
        const loadedOrder = res;
        // check for issue reading order from api
        if (loadedOrder === null) {
          console.error('Unable to retrieve order_id', order_id);
          setIsLoading(false);
          setLoaded(true);
        } else {
          const newStatus = success ? 'success' : 'canceled';
          if (newStatus === 'canceled') setRequestsCreated(true); // by-pass request creation
          if (loadedOrder.status !== newStatus) {
            const updates = {
              orderId: order_id,
              status: newStatus,
              open: success
            }
            updateOrder(updates)
              .then(() => {
                setIsLoading(false);
                setLoaded(true);
              });
          }
          setOrder(loadedOrder);
          setIsLoading(false);
          setLoaded(true);
        }
      })
    }
  }, [order_id])

  // Step 2: Create the requests and add them to the order
  useEffect(() => {
    // console.log('loaded', loaded, 'order?.status', order?.status);
    if (loaded) {
      if (order.requests.length === 0) {
        if (debug) console.log('OrdersStripe | USE_EFFECT | create requests');
        setIsProcessing(true);
        if (debug) console.log('OrdersStripe | creating requests for orderId:', order?.orderId);
        // create the requests -- but catch if they have already been created
        createRequests(order)
          .then((requests) => {
              const requestIds = requests.map((r) => r.id);
              if (debug) console.log('OrdersStripe | requestIds ', requestIds);
              updateOrder({orderId: order.orderId, requests: requestIds})
                .then((res) => {
                    if (debug) console.log('updatedOrder', res);
                    setIsProcessing(false);
                    setOrder(res);
                    setLoaded(true);
                  }
                );
            }
          );
      } else {
        if (debug) console.log('OrderStripe | USE_EFFECT | requests already created -- skipping');
        setRequestsCreated(true);
      }
    }
  }, [order, loaded]);

  if (debug) console.log('OrdersStripe | RENDER | order', order);
  if (debug) console.log('OrdersStripe | RENDER | loaded', loaded, 'isLoading', isLoading, 'requestsCreated', requestsCreated, 'isProcessing', isProcessing);

  return (
    <OrdersStripeContainer>
      { !loaded || isLoading || !requestsCreated || isProcessing ?
        <DataLoading />
      :
        <>
          <StatusMessageContainer>
            <StatusMessage>{ success ? 'Successfully created order' : 'Order Canceled'}</StatusMessage>
          </StatusMessageContainer>
          { success ?
            <ViewOrderDetails
              order={order}
              viewMode={canceled ? 'canceled' : 'success' }
              handleBack={()=>navigate('/orders/active')}
              topBuffer={150}
            />
          :
            <></>
          }
        </>
      }
    </OrdersStripeContainer>
  )
}


export default OrdersStripe;

const OrdersStripeContainer = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%
`;

const StatusMessage = styled.div`
  font-size: 14px;
  font-style: italic;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

