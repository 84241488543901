import React from 'react';
// import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

// import RangeSlider from '../components/controls/RangeSlider';
import TextField from '@mui/material/TextField';
// import { RootState } from '@src/store';
// import { updateFilter } from '@stores/filters';
import { AutoSizer } from 'react-virtualized';

const PgSearch = () => {
  // const dispatch = useDispatch();
  // this is not ok, I will comment it
  // const filters: any = useSelector((state: RootState) => state.filters);

  // const handleValueUpdate = (e, value, uuid): void => {
  //   console.log('HANDLE', value, uuid);
  //   dispatch(updateFilter({ value, uuid }));
  // };

  return (
    <Container>
      <AutoSizer disableWidth>
        {({ height }) => (
          <SearchContainer height={height}>
            <StyledTextField id='standard-basic' label='Compound Name' />

            {/* {filters?.map((item, index) => {
              return (
                <RangeSlider
                  key={index}
                  data={item}
                  handleUpdate={(e, value) => handleValueUpdate(e, value, item)}
                />
              );
            })} */}
          </SearchContainer>
        )}
      </AutoSizer>
    </Container>
  );
};

export default PgSearch;

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
`;

const SearchContainer = styled.div<{ height?: number }>`
  display: grid;
  grid-auto-flow: row;
  padding: 0.7rem;
  gap: ${(p) => p.theme.sizes.small} 0;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  height: ${(p) => p.height}px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTextField = styled(TextField)``;
