import { useCallback } from 'react';
import useCognito from '@as_core/account/useCognito';
import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';

import { CompoundT, CompoundCategoryT } from '@src/type';
import { ResponseT } from '@utils/api/base';
import { Compounds, PlateformAnalysis } from '@utils/api';
import { handleError } from '@utils/misc';

const useCompounds = () => {
  const { getToken } = useCognito();

  const convertBaseTablePropsToParams = useCallback((props) => {
    let params = {};
    if (_.has(props, 'key')) {
      params = {
        sort_by: _.get(props, 'key'),
        sort_order: _.get(props, 'order', '') === 'asc' ? 1 : -1,
      };
    }
    return params;
  }, []);

  const compoundIdsFromCompoundViews = useCallback((compoundViews, matchId) => {
    if (!Array.isArray(matchId)) {
      return _.get(compoundViews, `views.${matchId}.compoundIds`, []);
    }
  
    // Collect compoundIds from each matchId
    const combinedCompoundIds = matchId.reduce((acc, id) => {
      const compoundIds = _.get(compoundViews, `views.${id}.compoundIds`, []);
      return acc.concat(compoundIds);
    }, []);

    return [{
      library: 'user',
      compoundIds: combinedCompoundIds
    }];

  }, []);
  

  const getCompounds = async (
    params,
    signal?: object
  ): Promise<ResponseT<CompoundT[]>> => {
    const debug = false;
    const token = getToken();
    let config: AxiosRequestConfig = { params };

    if (signal) config = { ...config, ...signal };

    let resp;
    if (debug) console.log('config : ', config);
    try {
      resp = await Compounds.all(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    if (debug) console.log('resp : ', resp);
    if (resp.data.errors.length > 0) {
      console.error('getCompounds ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getCompoundsFiltered = async (data, params, signal?: object) => {
    const token = getToken();
    let config: AxiosRequestConfig = { params };

    if (signal) config = { ...config, ...signal };

    let resp;
    // console.log("calling old function")
    try {
      resp = await Compounds.filtered(token, data, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getCompounds ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const getCompoundsFinite = async (params, data, signal?: Record<string, AbortSignal>) => {
    const token = getToken();
    let config: AxiosRequestConfig = { params };

    if (signal) config = { ...config, ...signal };

    let resp;

    try {
      resp = await Compounds.list(token, data, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      //DHR fix later console.log('getCompoundsFinite ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getCompound = async (
    token,
    compoundId,
    params = null
  ): Promise<ResponseT<CompoundT>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Compounds.get(token, compoundId, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getCompound ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const deleteCompound = async (compoundId: string, params = null) => {
    // console.log("deleteCompound | compoundId", compoundId);
    const token = getToken();
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Compounds.delete(token, compoundId, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('deleteCompound ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const updateCompound = async (
    compoundUpdate,
    library: string = 'user',
    library_recurse: boolean = true
  ) => {
    const token = getToken();
    const config = {
      library,
      library_recurse,
    };
    let resp;

    try {
      resp = await Compounds.update(token, compoundUpdate, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('updateCompound ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const saveCompounds = async (fileData) => {
    const token = await getToken();
    let resp;
    try {
      resp = await Compounds.saveFile(token, fileData);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp;
  };

  const updateCompoundSVG = async (
    compoundId: string,
    svg: string,
    library: string = 'user'
  ) => {
    const token = getToken();
    const data = {
      uuid: compoundId,
      svg: svg,
    };
    const config = {
      library: library,
    };
    let resp;

    try {
      resp = await Compounds.updateSVG(token, data, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('updateCompoundSVG ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getNeighbors = async (compoundId, params) => {
    const token = getToken();
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Compounds.neighbors(token, compoundId, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getNeighbors ERRORS', resp.data.errors);
    }
    const data = resp.data.data;
    //console.log('getNeighbors DATA', data);
    //console.log('getNeighbors DATA.keys()', Object.keys(data));

    if (data?.sims) {
      const sims = data.sims;
      const libraries = Object.keys(sims);
      if (libraries.length === 1) {
        //console.log('getNeighbors libraries', libraries);
        return sims[libraries[0]];
      }
    }

    //console.log('getNeighbors SIMS', sims);
    //console.log('getNeighbors SIMS.keys', Object.keys(sims));

    return resp.data;
  };

  const calculateProperties = async (config: {
    smiles?: string;
    mol?: string;
  }) => {
    const token = getToken();
    let resp;

    try {
      resp = await Compounds.calc(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('calculateProperties ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getCompoundCategory = async (params): Promise<CompoundCategoryT[]> => {
    const token = getToken();
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Compounds.getCompoundCategory(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getCompoundCategory ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getFilteredTable = async (data) => {
    const token = await getToken();
    let resp;

    try {
      resp = await Compounds.getCompoundFiltered(token, data);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp;
  };

  const searchLibraries = async (params) => {
    const token = getToken();
    let resp;

    try {
      resp = await Compounds.searchLibraries(token, params);
    } catch (err) {
      handleError(err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      console.error('searchLibraries ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const fetchFilteredData = async (tableId) => {
    const token = await getToken();
    try {
      const response = await Compounds.getCompoundFilteredById(token, tableId);
      return response.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };
  // ToDo move under User
  const handleCreateNewFilter = async () => {
    const token = await getToken();
    const filterData = {
      filterList: [],
      filterName: 'MyFilter',
      is_temporary: true,
      columns: ['Column1', 'Column2'],
    };
    try {
      const response = await PlateformAnalysis.createFilters(token, filterData);

      return response.data.data.filter_uuid;
    } catch (err) {
      handleError(err);
      return null;
    }
  };
  const createTable = async (data) => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.createTable(token, data);
      return response.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };
  const getAllFilters = async (params = null) => {
    const token = await getToken();
    const config: AxiosRequestConfig = { params };
    let resp;
    try {
      resp = await PlateformAnalysis.getAllFilters(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp;
  };

  const updateFilterData = async (filterID, data) => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.updateFilters(
        token,
        filterID,
        data
      );
      return response.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const deleteFilterData = async (filter_id) => {
    const token = await getToken();
    let resp;
    try {
      resp = await PlateformAnalysis.deleteFilters(token, filter_id);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp;
  };

  const fetchAllData = async (list_columns = []) => {
    const token = await getToken();
    try {
      const response = await Compounds.getCompoundsAsync(token, list_columns);
      return response.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };
  const saveAggregate = async (payload) => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.calculateAggregation(
        token,
        payload
      );
      return response.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const deleteSaveWork = async (payload) => {
    const token = await getToken();
    let resp;
    try {
      resp = await PlateformAnalysis.deleteSavedWork(token, payload);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp;
  };
  const saveComponents = async (data) => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.saveComponents(token, data);
      return response.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const fetchSavedWork = async () => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.fetchSavedWork(token);
      return response.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const applySavedWork = async (payload) => {
    const token = await getToken();
    try {
      const response = await PlateformAnalysis.applySavedWork(token, payload);
      return response.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const removeFilterFromTable = useCallback(
    async (tableUUID: string | number, filterId: string) => {
      try {
        const token = await getToken();
        const response = await PlateformAnalysis.removeFilterFromTable(
          token,
          tableUUID,
          filterId
        );
        return response.data;
      } catch (error) {
        console.error('Error removing filter from table:', error);
        throw error;
      }
    },
    []
  );

  const deleteAggregateData = useCallback(async (aggregateId: string) => {
    try {
      const token = await getToken();
      await PlateformAnalysis.deleteAggregate(token, aggregateId);
      // Additional code to handle the successful deletion
    } catch (error) {
      console.error('Error deleting aggregate data:', error);
      throw error;
    }
  }, []);

  const deleteTable = useCallback(async (tableId: string) => {
    try {
      const token = await getToken();
      await PlateformAnalysis.deleteTable(token, tableId);
    } catch (error) {
      console.error('Error deleting table:', error);
      throw error;
    }
  }, []);

  const updateSavedWork = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (savedWorkId: string, data: any) => {
      try {
        const token = await getToken();
        await PlateformAnalysis.updateSavedWork(token, savedWorkId, data);
      } catch (error) {
        console.error('Error updating saved work:', error);
        throw error;
      }
    },
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadFile = useCallback(async (data: any) => {
    try {
      const token = await getToken();
      let res = await PlateformAnalysis.uploadFile(token, data);
      return res;
    } catch (error) {
      console.error('Error updating saved work:', error);
      throw error;
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finilizeUploadFile = useCallback(async (data: any) => {
    try {
      const token = await getToken();
      let res = await PlateformAnalysis.finilizeUploadFile(token, data);
      return res;
    } catch (error) {
      console.error('Error updating saved work:', error);
      throw error;
    }
  }, []);

  return {
    getCompound,
    updateCompound,
    getCompounds,
    deleteCompound,
    getCompoundsFiltered,
    getCompoundsFinite,
    getNeighbors,
    convertBaseTablePropsToParams,
    compoundIdsFromCompoundViews,
    calculateProperties,
    updateCompoundSVG,
    getCompoundCategory,
    saveCompounds,
    getFilteredTable,
    fetchFilteredData,
    handleCreateNewFilter,
    createTable,
    getAllFilters,
    updateFilterData,
    deleteFilterData,
    fetchAllData,
    saveAggregate,
    deleteSaveWork,
    saveComponents,
    fetchSavedWork,
    applySavedWork,
    removeFilterFromTable,
    deleteAggregateData,
    deleteTable,
    updateSavedWork,
    uploadFile,
    finilizeUploadFile,
    searchLibraries,
  };
};

export default useCompounds;
