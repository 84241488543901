import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { RootState } from '@src/store';
import { FieldSliceT, getFields } from '@stores/fields';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from '@mui/material';
import styles from '../../components-style/PagePanel.module.css';
import useCognito from '@as_core/account/useCognito';

const zebraFishImages = {
  NORM: '/img/zebra-fish/zf_normal.png',
  MORT: '/img/zebra-fish/zf_mortality.png',
  MO24: '/img/zebra-fish/zf_mortality.png',
  DP24: '/img/zebra-fish/zf_mortality.png',
  TCHR: '/img/zebra-fish/zf_mortality.png',
  EPR: '/img/zebra-fish/zf_mortality.png',
  'LPR-D': '/img/zebra-fish/zf_mortality.png',
  'LPR-L': '/img/zebra-fish/zf_mortality.png',
  BRN: '/img/zebra-fish/zf_brain.png',
  LTRK: '/img/zebra-fish/zf_lower_trunk.png',
  EYES: '/img/zebra-fish/zf_eye.png',
  JAW: '/img/zebra-fish/zf_jaw.png',
  AXIS: '/img/zebra-fish/zf_axis.png',
  EAR: '/img/zebra-fish/zf_ear.png',
  HRT: '/img/zebra-fish/zf_heart.png',
  PFIN: '/img/zebra-fish/zf_pectoral_fin.png',
  SKIN: '/img/zebra-fish/zf_skin.png',
  CRAN: '/img/zebra-fish/zf_cranial.png',
  SM24: '/img/zebra-fish/zf_mortality.png',
  SWBL: '/img/zebra-fish/zf_swim_bladder.png',
  MUSC: '/img/zebra-fish/zf_muscle.png',
  EDEM: '/img/zebra-fish/zf_edema.png',
  NC: '/img/zebra-fish/zf_notochord.png',
};

interface CountsDataT {
  field_id: string;
  symbol: string;
  count: string;
  long_name: string;
  short_description: string;
}

const mapDataRequest = (fields, allFields) => {
  return fields
    .filter((item) => item._id.includes('zf_'))
    .filter((item) => !item._id.includes('any'))
    .map((item) => ({
      field_id: item._id,
      count: item.count,
      symbol: _.get(allFields[item._id], 'symbol', ''),
      long_name: _.get(allFields[item._id], 'long_name', ''),
      short_description: _.get(allFields[item._id], 'short_description', ''),
    }));
};

const debug = false;
export default function ZebraFish() {
  // PARAMETERS
  const library = 'aseda';

  // API
  const dispatch = useDispatch();
  const { getToken } = useCognito();

  // STATES
  const [activeTab, setActiveTab] = useState('zf_cran');
  const [, setDataLoading] = useState(false);
  const [dataCounts, setDataCounts] = useState<CountsDataT[]>([]);

  // FIELD DEFINITIONS
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  if (debug) console.log('allFields', allFields);

  const handleClick = (e) => {
    setActiveTab(e);
  };

  const getImage = useMemo(() => {
    const activeSymbol = _.get(allFields[activeTab], 'symbol', '');
    if (debug) {
      console.log(
        'ZebraFish | getImage | activeTab:',
        activeTab,
        ' activeSymbol:',
        activeSymbol
      );
    }

    const imageSrc = zebraFishImages[activeSymbol ?? 'NORM'];

    const altText = activeSymbol || 'Normal or Unknown';

    console.log(imageSrc, zebraFishImages, activeSymbol);

    return (
      <img
        width={600}
        src={imageSrc}
        alt={altText}
        className={styles.zebrafishImg}
      />
    );
  }, [activeTab, allFields]);

  const getDescription = () => {
    return (
      <Description>
        <LongName>{_.get(allFields[activeTab], 'long_name', '')}</LongName>
        <ShortDescription>
          {_.get(allFields[activeTab], 'short_description', '')}
        </ShortDescription>
      </Description>
    );
  };

  // EFFECTS - DATA LOADING
  useEffect(() => {
    const token = getToken();
    dispatch(getFields({ token }));
  }, [dispatch]);

  useEffect(() => {
    const zf_counts = [
      { _id: 'zf_mo24', count: 146 },
      { _id: 'zf_dp24', count: 129 },
      { _id: 'zf_sm24', count: 2 },
      { _id: 'zf_mort', count: 248 },
      { _id: 'zf_cran', count: 169 },
      { _id: 'zf_axis', count: 152 },
      { _id: 'zf_edem', count: 195 },
      { _id: 'zf_musc', count: 90 },
      { _id: 'zf_ltrk', count: 83 },
      { _id: 'zf_brn', count: 118 },
      { _id: 'zf_skin', count: 10 },
      { _id: 'zf_nc', count: 17 },
      { _id: 'zf_tchr', count: 124 },
      { _id: 'zf_any_effect', count: 286 },
      { _id: 'zf_epr', count: 222 },
      { _id: 'zf_lpr_d', count: 131 },
      { _id: 'zf_lpr_l', count: 136 },
      { _id: 'tanguay_casrn', count: 802 },
    ];
    const data = mapDataRequest(zf_counts, allFields);
    setDataCounts(data || []);
    setDataLoading(false);
    {
      /*
    if (!dataLoading) {
      setDataLoading(true);
      const config = { library: library, assay: 'tanguay_zf' };
      getAssayFields(config).then((response) => {
        setDataLoading(false);
        const data = mapDataRequest(response?.data.dataFields, allFields);
        setDataCounts(data || []);
      });
    }
    return () => {
      setDataLoading(false);
      cancelSource.cancel('exit');
    };
    */
    }
  }, [library, allFields]);
  if (debug) console.log('ZebraFish | dataCounts: ', dataCounts);

  return (
    <ZebrafishContainer>
      <Row>
        <TabWrapper>
          {dataCounts.map((zf, i) => (
            <Tab
              key={'zf_' + i}
              className={zf.field_id === activeTab ? 'active' : ''}
              onClick={() => handleClick(zf.field_id)}
            >
              <TabNumber>{zf.count}</TabNumber>
              <TabName>{zf.symbol}</TabName>
            </Tab>
          ))}
        </TabWrapper>
      </Row>
      <Row>{getDescription()}</Row>
      <Row>{getImage}</Row>
    </ZebrafishContainer>
  );
}

const ZebrafishContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
`;

const TabWrapper = styled.div`
  color: ${(p) => p.theme.palette.titlePrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 80%;
  justify-content: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const LongName = styled.div`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin: 0;
  padding: 10px 0 0 0;
`;

const ShortDescription = styled.div`
  display: flex;
  height: 18px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const TabNumber = styled.div`
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const TabName = styled.div`
  display: flex;
  font-size: 12px;
`;

const Tab = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  &.MuiButtonBase-root {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
    border-radius: 10px;
    margin: 5px;
    padding: 2px;
    width: 60px;
    height: 40px;
  }
  &.active {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textPrimary};
  }
  :hover {
    background-color: ${(p) => p.theme.palette.accentSecondary};
  }
`;
