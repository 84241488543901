import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import useFileImport from '../../useFileImport';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { TextSmall } from '@components/elements/TextStyles';
import { Row, Header, Cell } from '../../shared/RowElements';
import useCompounds from '@components/compounds/useCompounds';
import { CompoundcFieldT } from '@src/type';
import ResultCell from './ResultCell';
import { useSelector } from 'react-redux';
import { RootState } from "@src/store";
import { FieldSliceT } from '@stores/fields';
import InProcessSpinner from '@components/elements/InProcessSpinner';
import DataUploadIcon from '@components/icons/dataUpload.icon';
import TextButton from "@components/controls/TextButton";
import { CompoundT } from '@src/type';

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
interface ImportReviewProps {
  height: number;
  width: number;
  compoundSet: CompoundT[];
  setCompoundSet: (compounds: CompoundT[]) => void;
  handleNext: () => void;
  showUpload: boolean;
}

type PreviewRowT = {
  index: number;
  identity: string;
  libraries: Record<string, boolean | string | boolean | undefined>[];
};

const ImportReview = (props: ImportReviewProps) => {
  const { height, width, setCompoundSet, showUpload, handleNext } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  if (debug) console.log('ImportReview', allFields);
  const [loading, setLoading] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [preview, setPreview] = React.useState<PreviewRowT[]>(null);

  const {
    context: { columns, libraries },
  } = useFileImport();
  const identity = columns.find((c) => c.fieldFilter === 'Identity');

  const { searchLibraries } = useCompounds();

  // return uuid of user library if found -- otherwise not
  function getCompoundUUID(compoundsFound, cpdId) {
    if (
      Object.hasOwn(compoundsFound, cpdId) &&
      Object.hasOwn(compoundsFound[cpdId], 'user')
    ) {
      return compoundsFound[cpdId]['user'];
    }
    return uuidv4();
  }

  // return uuid of user library if found -- otherwise not
  function getLibraries(compoundsFound, cpdId) {
    if (!Object.hasOwn(compoundsFound, cpdId)) {
      return [];
    }
    let libraries = [];
    for (const library of Object.keys(compoundsFound[cpdId])) {
      libraries.push({
        libraryId: library,
        compoundUUID: compoundsFound[cpdId][library],
      });
    }
    return libraries;
  }

  // return uuid of user library if found -- otherwise not
  function inLibrary(compoundsFound, cpdId, library) {
    if (!Object.hasOwn(compoundsFound, cpdId)) return false;
    return Object.hasOwn(compoundsFound[cpdId], library);
  }

  // handle the search/matching against the identity in the available libraries
  useEffect(() => {
    if (!loading) {
      const searchValues = identity?.data.filter((c) => c !== undefined);
      setLoading(true);
      const payload = {
        search: identity?.field_id,
        values: searchValues,
      };
      searchLibraries(payload)
        .then((result) => {
          // TODO -- redo as map
          let compoundsFound = {};
          for (const row of result.data) {
            compoundsFound[row?.data_value] = {};
            for (const item of row.compounds) {
              compoundsFound[row?.data_value][item?.library] = item?.uuid;
            }
          }
          // create the compound dataset for the next step to create/upload
          let compounds = [];
          let preview = [];
          const mappedColumns = columns.filter((c) => c?.field_id !== null);
          debug && console.log('ImportReview | mappedColumns', mappedColumns);
          for (let index = 0; index < identity?.data.length; index++) {
            let compoundId = identity?.data[index];
            if (compoundId !== undefined) {
              compounds[index] = {
                uuid: getCompoundUUID(compoundsFound, compoundId),
                libraries: getLibraries(compoundsFound, compoundId),
                cfields: mappedColumns.reduce((o, cur) => {
                  let value = cur?.data[index];
                  if (value === undefined) value = null;
                  return {
                    ...o,
                    [cur.field_id]: {
                      value: value,
                      source_id: 'new',
                    },
                  } as CompoundcFieldT;
                }, {} as CompoundcFieldT),
              };
              // note -- just use cfields and field_ids-- this is sorted out in the api
              let libMap = {};
              libraries.map((lib) => {
                libMap[lib?.id] = inLibrary(
                  compoundsFound,
                  compoundId,
                  lib?.id
                );
              });
              preview.push({
                index: index + 1,
                identity: compoundId,
                libraries: libMap,
              });
            }
          }
          setLoading(false);
          setSearchComplete(true);
          setPreview(preview);
          setCompoundSet(compounds);
        })
        .catch();
    }
  }, []);

  if (debug) console.log('loading', loading, 'searchComplete', searchComplete);
  // ###########################################################################
  // RENDER
  // ###########################################################################
  return (
    <Container height={height - 50} width={width}>
      <ControlContainer>
        {loading ? (
          <FlexRow height={'50px'} h_centered v_centered>
            <FlexItem>
              <InProcessSpinner width={25} />
            </FlexItem>
            <FlexItem>
              <TextSmall>- Searching identities to link</TextSmall>
            </FlexItem>
          </FlexRow>
        ) : (
          <FlexRow height={'50px'} h_centered v_centered>
            <TextSmall>
              {preview?.length
                ? preview.length + ' compounds searched (ready to upload)'
                : null}
            </TextSmall>
          </FlexRow>
        )}
      </ControlContainer>
      <ScrollContainer height={340}>
        <Table key='ScrollContainerTable'>
          <tbody>
            <Row key='Header Row'>
              <Header>Row</Header>
              <Header key={`idCol-${identity.colIdx}`}>
                {identity.colName} [Identity]
              </Header>
              {libraries
                .filter((library) => library.selected === true)
                .map((library) => (
                  <Header key={`libCol-${library.id}`}>{library.name}</Header>
                ))}
            </Row>
            {!loading && searchComplete ? (
              preview.map((row, index) => (
                <Row key={`rows-${index}`}>
                  <Cell>{row?.index}</Cell>
                  <Cell>{row?.identity}</Cell>
                  {libraries.map((library) => (
                    <ResultCell
                      key={`library-${library.id}`}
                      library={library.id}
                      present={row?.libraries[library.id]}
                    />
                  ))}
                </Row>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </ScrollContainer>
      {showUpload && (
        <Center>
          <ButtonContainer>
            <TextButton
              text={'Upload Data'}
              height={30}
              width={200}
              onClick={() => handleNext()}
              icon={<DataUploadIcon size={32} />}
            />
          </ButtonContainer>
        </Center>
      )}
    </Container>
  );
};

export default ImportReview;

const Container = styled.div<{ height?: number; width?: number }>`
  display: block;
  position: relative;
`;

const ControlContainer = styled.div`
  width: 100%;
  height: 50px;
`;

const ScrollContainer = styled.div<{ height?: number; width?: number }>`
  max-height: calc(100vh - ${(p) => p.height}px);
  border-radius: 15px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  table-layout: auto;
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;